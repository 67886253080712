<style src="./login.component.scss" lang="scss"> </style>

<template>
  <v-card>
    <v-button
        class="login__button"
        @click="checkLoginStatus"
    >
      Login
    </v-button>
  </v-card>
</template>

<script src="./login.component.ts"></script>