import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {LoginSandbox} from "@/pages/login/sandbox/sandbox.sandbox";
import {container} from 'tsyringe';
import {store} from "@/store";

@Options({
  name: 'login-component',
  props: {
    type: Function,
    required: true
  }
})

export default class LoginComponent extends Vue {
  private store = store
  public sandbox: LoginSandbox = container.resolve(LoginSandbox);
  private loginData: any = null;

  public checkLoginStatus(): any {
    this.sandbox.checkLogin().then((res) => {
      this.loginData = res;
    });
  }
}