<style src="./contact-info.component.scss" lang="scss"></style>

<template>
  <div class="contact-info mb-100">
    <div class="row">
      <div class="col-12">
        <v-card v-if="userDetails.length===0">
          <div class="contact-info__wrapper d-flex justify-between align-center"
               v-if="!isHiddenCreateContact">
            <div class="contact-info__information">
              Əlaqə məlumatlarınızı daxil edin
            </div>
            <v-btn
                class="px-40 py-12"
                color="dark"
                @click="isHiddenCreateContact =!isHiddenCreateContact">
              Əlavə et
            </v-btn>
          </div>
          <div v-else class=" mb-40">
            <div class="contact-info--add-data">
              <form class="form mb-20" @submit.prevent="sendDataPost">
                <div class="row">
                  <div class="col-lg-4 col-md-9 col-12">
                    <div class="form__group" v-for="(item, index) in formDataPhone">
                      <div class="form__wrapper">
                        <label
                            :class="{'v-label--error':errorPhone[index].phone}"
                            class="v-label mb-7">{{ item.name }}:</label>
                        <input
                            @keyup="deleteNumberInput($event, index)"
                            @keydown="deleteNumberInput($event, index)"

                            placeholder="+994("
                            v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                            :class="{'v-input--error':errorPhone[index].phone}"
                            type="text" class="v-input" v-model="item.value">
                      </div>
                      <img
                          v-if="formDataPhone.length>1"
                          @click="deleteNumber(1,index)"
                          src="@/assets/images/icons/delete-number.svg" alt="">
                    </div>
                    <div class="form__info" @click="addInfo(1)">
                      <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">
                      Telefon əlavə et
                    </div>
                  </div>
                  <div class="w-100 d-none d-md-block"></div>
                  <div class="col-lg-4 col-md-9 col-12">
                    <div class="form__group" v-for="(item, index) in formDataMail">
                      <div class="form__wrapper">
                        <label
                            :class="{'v-label--error':errorEmail[index].email}"
                            class="v-label mb-7">{{ item.name }}:</label>
                        <input

                            placeholder="E-mailinizi daxil edin"
                            :class="{'v-input--error':errorEmail[index].email}"
                            type="text" class="v-input" v-model="item.value">
                      </div>
                      <img
                          v-if="formDataMail.length>1"
                          @click="deleteNumber(2,index)"
                          src="@/assets/images/icons/delete-number.svg" alt="">
                    </div>
                    <div class="form__info" @click="addInfo(2)">
                      <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">
                      E-mail əlavə et
                    </div>
                  </div>
                  <div class="col-12">
                    <v-divider class="mt-30 mb-30"/>
                    <div class="edit-buttons text-center mt-30">
                      <v-btn class="btn--black mr-3 py-12 px-40"
                             v-on:click="isHiddenCreateContact = !isHiddenCreateContact">
                        {{ localeElements.cancel }}
                      </v-btn>
                      <v-btn class="btn btn--dark ml-4 py-12 px-40">
                        {{ localeElements.save }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </v-card>
        <v-card v-else class="contact-info__details">
          <div class="contact-info__wrapper" v-if="!isHiddenUpdateContact">
            <div class="row">
              <div class="col-md-4  col-12">
                <ul class="contact-info__list">
                  <template v-for="(item, index) in userDetails" :key="index">
                    <li v-if="item.userContactTypeId === 1">
                      <div class="contact-info__list-name">
                        Telefon:
                      </div>
                      <div class="contact-info__list-description">
                        {{ item.value }}
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="col-md-4  col-12">
                <ul class="contact-info__list">
                  <template v-for="(item, index) in userDetails" :key="index">
                    <li v-if="item.userContactTypeId === 2">
                      <div class="contact-info__list-name">
                        E-mail:
                      </div>
                      <div class="contact-info__list-description">
                        {{ item.value }}
                      </div>
                    </li>
                  </template>
                </ul>
              </div>

              <v-btn class="py-8 px-20 edit-details">
                <img class="mr-8" src="@/assets/images/icons/edit.svg" alt="edit_icon"
                     @click="isHiddenUpdateContact = !isHiddenUpdateContact">
              </v-btn>
            </div>
          </div>
          <div v-else class=" mb-40">
            <div class="contact-info--edit-data">
              <form class="form mb-20" @submit.prevent="sendDataPut">
                <div class="row">
                  <div class="col-lg-4 col-md-9 col-12">
                    <div class="form__group" v-for="(item, index) in formDataPhone">
                      <div class="form__wrapper">
                        <label
                            :class="{'v-label--error':errorPhone[index].phone}"
                            class="v-label mb-7">{{ item.name }}:</label>
                        <input
                            @keyup="deleteNumberInput($event, index)"
                            @keydown="deleteNumberInput($event, index)"

                            placeholder="+994("
                            v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                            :class="{'v-input--error':errorPhone[index].phone}"
                            type="text" class="v-input" v-model="item.value">
                      </div>
                      <img
                          v-if="formDataPhone.length>1"
                          @click="deleteNumber(1,index)"
                          src="@/assets/images/icons/delete-number.svg" alt="">
                    </div>
                    <div class="form__info" @click="addInfo(1)">
                      <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">
                      Telefon əlavə et
                    </div>
                  </div>
                  <div class="w-100 d-none d-md-block"></div>
                  <div class="col-lg-4 col-md-9 col-12">
                    <div class="form__group" v-for="(item, index) in formDataMail">
                      <div class="form__wrapper">
                        <label
                            :class="{'v-label--error':errorEmail[index].email}"
                            class="v-label mb-7">{{ item.name }}:</label>
                        <input

                            placeholder="E-mailinizi daxil edin"
                            :class="{'v-input--error':errorEmail[index].email}"
                            type="text" class="v-input" v-model="item.value">
                      </div>
                      <img
                          v-if="formDataMail.length>1"
                          @click="deleteNumber(2,index)"
                          src="@/assets/images/icons/delete-number.svg" alt="">
                    </div>
                    <div class="form__info" @click="addInfo(2)">
                      <img src="@/assets/images/icons/plus.svg" alt="" class="mr-12">
                      E-mail əlavə et
                    </div>
                  </div>
                  <div class="col-12">
                    <v-divider class="mt-30 mb-30"/>
                    <div class="edit-buttons text-center mt-30">
                      <v-btn class="btn--black mr-3 py-12 px-40"
                             @click="isHiddenUpdateContact = !isHiddenUpdateContact">
                        {{ localeElements.cancel }}
                      </v-btn>
                      <v-btn class="btn btn--dark ml-4 py-12 px-40">
                        {{ localeElements.save }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script src="./contact-info.component.ts"></script>
