<style src="./error-card.component.scss" lang="scss"> </style>

<template>
    <div class="error-card">
      <div class="error-card__wrapper text-center">
        <div class="error-card__img text-center">
          <img src="@/assets/images/icons/404.svg" alt="404">
        </div>
        <div class="error-card__title">{{locale.title}}</div>
        <div class="error-card__descr">{{locale.description}}</div>
        <router-link :to="{name: 'home'}" class="header__user-info">
          <v-btn  class="py-12 px-20 mt-50" color="dark">{{locale.button}}</v-btn>
        </router-link>

      </div>
    </div>
</template>

<script src="./error-card.component.ts"></script>
