import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class LoginSandbox {
    private store = store;

    checkLogin(): Promise<any> {
        return new Promise((resolve) => {
            this.store.dispatch('getTokenData', '1')
                .then(() => resolve(this.store.getters.checkLogin));
        });
    }
}