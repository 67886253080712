import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';

@Options({
    name: 'user-info-component',
})

export default class UserInfoComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
            }
        });
    }

    get userData() {
        return [
            {
                "title": "contactDocumentNumber",
                "data": this.userDocument
            },
            {
                "title": "contactDateBirth",
                "data": this.userBirthDate
            },
            {
                "title": "contactPersonalIdentificationNumber",
                "data": this.userPin
            },
            {
                "title": "contactRegisteredAddress",
                "data": this.userAddress
            },
            {
                "title": "contactFullName",
                "data": this.userInfo,
            },
        ]
    }

    get userInfo() {
        return store.getters._getUserNSF;
    }

    get userPin() {
        return store.getters._getUserPin;
    }

    get userDocument() {
        return store.getters._getUserDocument;
    }
    get userBirthDate() {
        return store.getters._getUserBirthDate;
    }
    get userAddress() {
        return store.getters._getUserAddress;
    }
    get userPhoto() {
        return store.getters._getUserPhoto;
    }
}
