<style src="./user-account.component.scss" lang="scss"></style>

<template>
  <div class="wrapper">
    <customer-info-component class="wrapper_customer" :user="userData"/>
    <div class="col-lg-3">

      <v-btn class="btn btn--dark ml-4 py-12 px-40  wrapper_btn" @click="getUserNewDetails">
        {{ locale.refresh }}
      </v-btn>
    </div>
  </div>
  <!--  <user-info-component/>-->
  <contact-info-component/>
</template>


<script src="./user-account.component.ts"></script>

