import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class UserAccountSandbox {
    private store = store;

    constructor() {
    }

    getUserDetails(payload: any): void {
        this.store.dispatch('getUserDetails', payload);
    }


    postUserDetails(payload: any): any {
        return this.store.dispatch('postUserDetails', payload);
    }

    putUserDetails(payload: any): any {
        return this.store.dispatch('putUserDetails', payload);
    }
}
