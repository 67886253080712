<style src="./not-found.component.scss" lang="scss"></style>

<template>
  <div class="not-found">
    <header-component/>
    <error-card-component/>
  </div>
</template>

<script src="./not-found.component.ts"></script>
