import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import SearchComponent from '@/core/layouts/public/components/search/search.component.vue';
import {container} from 'tsyringe';
import {DbService} from '@/assets/db/db.service.ts';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
@Options({
    name: 'header-component',
    components: {
        SearchComponent,
    },
})

export default class HeaderComponent extends Vue {
    private localizationService = container.resolve(DbService);
    public locale$: any = locale;
    public locale: any = null;
    public sandbox: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    private store = store;
    public isVisibleSearch = true;
    get userInfo() {
        return this.store.getters.getUser;
    }
    get userPhoto() {
        return this.store.getters.getPhoto;
    }

    public setLocale($event: any): void {
        this.localizationService.setNewLang($event.target.value);
    }

    public login(): void {
        this.sandbox.getSSOToken();
    }

    public logout(): void {
        this.sandbox.logout();
    }
    public created() {
        this.locale$.subscribe((res: any) => {
            if (res && res.home) {
                this.locale = res.home.header;
            }
        });
    }

}
