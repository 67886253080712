import { Options, Vue } from 'vue-class-component';
import NotFound from '@/core/layouts/not-found/not-found.component';
import HeaderComponent from '@/core/layouts/not-found/components/header/header.component.vue';
import ErrorCardComponent from '@/core/layouts/not-found/components/error-card/error-card.component.vue';
@Options({
    name: 'not-found-component',
    components: {
        NotFound,
        HeaderComponent,
        ErrorCardComponent
    },
})
export default class NotFoundComponent extends Vue {
}
