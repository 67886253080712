import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'error-card-component',
    components: {},
})
export default class ErrorCardComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.error) {
                this.locale = res.error;
            }
        });
    }
}
