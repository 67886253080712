<style src="./user-info.component.scss" lang="scss"></style>

<template>
  <v-card dark class="customer-info mb-20" v-if="userInfo.length">
    <div class="d-flex">
      <img class="customer-info__image mr-20" :src="userPhoto" alt="user photo" width="140"/>
      <div class="customer-info__list">
        <div class="row">
          <div class="col-6 mb-20" v-for="(item, index) in userData" :key="index">
            <div class="customer-info__field">
              {{ locale[item.title] }}
            </div>
            <div class="customer-info__data">{{ item.data }}</div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script src="./user-info.component.ts"></script>
