import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import UserInfoComponent from "@/pages/user-account/components/user-info/user-info.component.vue";
import ContactInfoComponent from "@/pages/user-account/components/contact-info/contact-info.component.vue";
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {ServicesSandbox} from "@/pages/services/sandbox/services.sandbox";
import {container} from "tsyringe";
import {UserAccountSandbox} from "@/pages/user-account/sandbox/user-account.sandbox";
import {CommonSandbox} from "@/sandbox/common.sandbox";

@Options({
  name: 'user-account-component',
  components: {
    UserInfoComponent,
    ContactInfoComponent,
    CustomerInfoComponent,
  }
})

export default class UserAccountComponent extends Vue {
  public sandbox: CommonSandbox = container.resolve(CommonSandbox);
  public store = store;
  public locale$: any = locale;
  public locale: any = null;
  public created(): void {
    this.locale$.subscribe((res: any) => {
      console.log(res,'res')
      if (res) {
        this.locale = res.elements;
      }
    });
  }
  get userData() {
    return store.getters._getUserFullData;
  }
  get getNewUserData() {
    return store.getters._getUserNewDetails;
  }
  public  getUserNewDetails() {
    this.sandbox.getUserNewDetails();
  }
}
