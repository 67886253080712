<style src="./header.component.scss" lang="scss"> </style>

<template>
  <div class="header">
    <div class="container-fluid v-container">
      <div class="row align-center">
        <div class="col-6">
          <div class="header__logo">
            <router-link :to="{name: 'home'}">
              <img alt="logo" src="@/assets/images/common/main-logo.svg">
            </router-link>
          </div>
        </div>
        <div class="col-6 text-right">
          <div class="header__login">
            <span class="header__call">
              <img src="@/assets/images/icons/call.svg" alt="call">
              155 {{locale.callCenter}}
            </span>
            <span v-if="userInfo.length!==1" class="header__user">
              <router-link :to="{name: 'user-account'}" class="header__user-info">
                <img :src="userPhoto" class="header__user-image">
                {{ userInfo }}
              </router-link>

              <v-btn  class="p-0" @click="logout">
                <img src="@/assets/images/icons/logout.svg" alt="logout">
              </v-btn>
            </span>
            <v-btn v-else color="white" @click="login">
              <img src="@/assets/images/common/asan-login.svg" alt="asan-login">
            </v-btn>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./header.component.ts"></script>
