import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import UserInfoComponent from '@/pages/user-account/components/user-info/user-info.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {UserAccountSandbox} from '@/pages/user-account/sandbox/user-account.sandbox';
import {container} from 'tsyringe';
import swal from 'sweetalert2';
import {IUserDetailsView} from '@/pages/user-account/types/user-details-view';
import {IUserDetails} from '@/pages/user-account/types/user-details';

@Options({
    name: 'contact-info-component',
    components: {
        UserInfoComponent
    },
    watch: {
        userDetails: {
            handler: 'onUserDetailsUpdateChanged',
            immediate: false,
            deep: false,
        }
    }
})

export default class ContactInfoComponent extends Vue {
    private sandbox: UserAccountSandbox = container.resolve(UserAccountSandbox);
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeElements: any = null;
    public isHiddenCreateContact = false;
    public isHiddenUpdateContact = false;
    public isHidden = false;
    public req = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    public errorPhone = [{
        phone: false,
    }];
    public errorEmail = [{
        email: false,
    }];
    public formDataPhone: IUserDetailsView[] = [{
        name: 'Telefon',
        userContactTypeId: 1,
        value: '+994(',
        userId: null,
        id: null
    }];

    public formDataMail: IUserDetailsView[] = [{
        name: 'E-mail',
        userContactTypeId: 2,
        value: '',
        userId: null,
        id: null
    },];

    get userData() {
        return this.store.getters._getUserFullData;
    }

    get userDetails(): [] {
        return this.store.getters._getUserDetails;
    }

    public onUserDetailsUpdateChanged(val: any) {
        let phones: IUserDetailsView[] = [];
        let email: IUserDetailsView[] = [];
        if (val) {
            this.userDetails.forEach((item: IUserDetails) => {
                if (item.userContactTypeId === 1) {
                    phones.push({
                        name: 'Telefon',
                        userContactTypeId: 1,
                        value: item.value,
                        userId: item.userId,
                        id: item.id
                    });
                }
                if (item.userContactTypeId === 2) {
                    email.push({
                        name: 'E-mail',
                        userContactTypeId: 2,
                        value: item.value,
                        userId: item.userId,
                        id: item.id
                    });
                }
            });
        }
        if (phones.length !== 0) {
            this.formDataPhone = [];
            this.errorPhone = [];
            this.formDataPhone = phones;
            phones.forEach((item) => {
                this.errorPhone.push({
                    phone: false
                });
            });
        }
        if (email.length !== 0) {
            this.formDataMail = [];
            this.formDataMail = email;
            email.forEach((item) => {
                this.errorEmail.push({
                    email: false
                });
            });
        }

    }

    public deleteNumberInput(event: any, index: number) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (this.formDataPhone[index].value.length <= 5) {
                this.formDataPhone[index].value = '+994(';
            }
        }
    }

    public deleteNumber(element: number, index: number) {
        if (element === 1) {
            this.formDataPhone.splice(index, 1);
            this.errorPhone.splice(index, 1);
        }
        if (element === 2) {
            this.formDataMail.splice(index, 1);
            this.errorEmail.splice(index, 1);
        }
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.user) {
                this.locale = res.user;
                this.localeElements = res.elements;
            }
        });
        this.sandbox.getUserDetails(localStorage.getItem('mainToken'));
    }

    public validationForm() {
        this.formDataPhone.forEach((item, index) => {
            if (item.value.length === 18) {
                this.errorPhone[index].phone = false;
            } else {
                this.errorPhone[index].phone = true;
            }
        });
        this.formDataMail.forEach((item, index) => {
            if (this.req.test(item.value)) {
                this.errorEmail[index].email = false;
            } else {
                this.errorEmail[index].email = true;
            }
        });
    }

    public validationFormCheck() {
        this.validationForm();
        let check = false;
        this.errorPhone.forEach((item) => {
            if (item.phone) {
                check = true;
            }
        });
        this.errorEmail.forEach((item) => {
            if (item.email) {
                check = true;
            }
        });
        return check;
    }

    public addInfo(id: number) {
        if (id === 1) {
            this.formDataPhone.push({
                name: 'Telefon',
                userContactTypeId: 1,
                value: '+994(',
                userId: null,
                id: null
            });
            this.errorPhone.push({phone: false});
        }
        if (id === 2) {
            this.formDataMail.push({
                name: ' E-mail',
                userContactTypeId: 2,
                value: '',
                userId: null,
                id: null
            });
            this.errorEmail.push({email: false});
        }
    }


    public sendDataPost() {
        if (!this.validationFormCheck()) {
            let formData = [...this.formDataPhone, ...this.formDataMail];
            this.sandbox.postUserDetails({
                token: localStorage.getItem('mainToken'),
                data: formData
            }).then((res: any) => {
                if (res.status === 200) {
                    swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Uğurla yaradıldı',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    this.sandbox.getUserDetails(localStorage.getItem('mainToken'));
                    this.isHidden = false;
                } else {
                    swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Xəta baş verdi',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            });
        }
    }

    public sendDataPut() {
        if (!this.validationFormCheck()) {
            let formData = [...this.formDataPhone, ...this.formDataMail];
            this.sandbox.putUserDetails({
                token: localStorage.getItem('mainToken'),
                data: {userContacts: formData}
            }).then((res: any) => {
                if (res.status === 200) {
                    swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Uğurla dəyişdirildi',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    this.sandbox.getUserDetails(localStorage.getItem('mainToken'));
                    this.isHidden = false;
                } else {
                    swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Xəta baş verdi',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            });
            this.isHiddenUpdateContact = false;
        }
    }
}




